import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as FormActions from './form.actions';
import { FormService } from './form.service';

@Injectable()
export class FormEffects {
  constructor(
    private actions$: Actions,
    private formService: FormService,
    private store: Store,
    private navCtrl: NavController
  ) {}

  loadForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadForm),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver)
      ),
      concatMap(([{ id }, user, serviceReceiver]) => {
        if (!user) return of(FormActions.loadFormFailure());
        return this.formService
          .fetchFormNewComplete(user, id, serviceReceiver)
          .pipe(
            map((form) => {
              return FormActions.loadFormSuccess({
                form,
              });
            }),
            catchError(() => of(FormActions.loadFormFailure()))
          );
      })
    )
  );

  saveForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.saveForm),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      concatMap(
        ([
          { id, formAnswers, redirection },
          user,
          serviceReceiver,
          selectedUser,
        ]) => {
          if (!user || !serviceReceiver || !selectedUser)
            return of(UserActions.logout());
          return this.formService
            .saveForm(user, serviceReceiver, formAnswers, id)
            .pipe(
              map((_) => FormActions.saveFormSuccess({ id, redirection })),
              catchError(() => of(FormActions.saveFormFailure()))
            );
        }
      )
    )
  );

  saveFormSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.saveFormSuccess),
      switchMap(({ id, redirection }) => {
        this.navCtrl.navigateForward(redirection);
        return [FormActions.loadFormStatus({id}), FormActions.loadFormSummary({ id })];
      })
    )
  );

  loadFormSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadFormSummary),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      concatMap(([{ id }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver || !selectedUser) {
          return of(UserActions.logout());
        }

        return this.formService
          .fetchFormSummary(id, user, serviceReceiver, selectedUser)
          .pipe(
            map((form) => {
              return FormActions.loadFormSummarySuccess({
                form,
              });
            }),
            catchError(() => of(FormActions.loadFormSummaryFailure()))
          );
      })
    )
  );

  loadFormStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FormActions.loadFormStatus),
      withLatestFrom(
        this.store.select(UserSelectors.selectUser),
        this.store.select(UserSelectors.selectServiceReceiver),
        this.store.select(UserSelectors.selectSelectedUser)
      ),
      concatMap(([{ id }, user, serviceReceiver, selectedUser]) => {
        if (!user || !serviceReceiver) {
          return of(FormActions.loadFormStatusFailure());
        }

        return this.formService
          .fetchFormStatus(id, user, serviceReceiver, selectedUser)
          .pipe(
            map((status) => {
              return FormActions.loadFormStatusSuccess({
                status,
              });
            }),
            catchError(() => of(FormActions.loadFormStatusFailure()))
          );
      })
    )
  );
}
