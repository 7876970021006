import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NewMember } from '@nai-libs/shared/data-access/src';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';

@Component({
    selector: 'nai-delete-member-success-dialog',
    template: `
    <button [mat-dialog-close] class="button button--icon-close">
      <span naiDynIcon [path]="'assets/icons/close.svg'" (click)="reloadcontent()"></span>
    </button>
    <div mat-dialog-content class="dialog-content">
      <figure>
        <img [src]="'assets/icons/alert-error.svg'" />
      </figure>
      <div class="text-wrapper">
        <h1 class="font-headline-3">
          {{ this.data.member['first-name'] }}
          {{ this.data.member['last-name'] }}
        </h1>
        <p class="font-headline-4">ha sido eliminado/a</p>
      </div>
    </div>
  `,
    styleUrls: ['./delete-member-success-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, DynamicIconDirective],
})
export class DeleteMemberSuccessDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      member: NewMember;
    }
  ) {}

  reloadcontent() {
    window.location.reload();
  }
}