import { Component, HostListener, OnInit, inject } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';

import { NotificationActions } from '@nai-libs/notification/data-access/src';


import { UserActions, UserSelectors } from '@nai-libs/user/data-access/src';
import { Store } from '@ngrx/store';



// import {
//   ActionPerformed,
//   PushNotificationSchema,
//   PushNotifications,
//   Token,
// } from '@capacitor/push-notifications';

@Component({
  selector: 'nai-root',
  template: `
    <ion-app [class.role-carer]="(userRole$ | async) === 'Cuidadora formal'">
      <ion-router-outlet id="main-content"></ion-router-outlet>
      
      <nai-menu></nai-menu>
      
      
      
    </ion-app>
  `,
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  userRole$ = this.store.select(UserSelectors.selectLoggedUserRole);
  constructor(
    private store: Store,
    private screenOrientation: ScreenOrientation,
    private platform: Platform,
    private navCtrl: NavController,
    
  ) {
    if (Capacitor.getPlatform() !== 'web' && this.platform.is('mobile')) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
    // Navigate back on HW back button press
    CapacitorApp.addListener('backButton', () => {
      this.navCtrl.back();
    });
  }


  

  ngOnInit() {


    
    // console.log('Initializing App');
    // // Request permission to use push notifications
    // // iOS will prompt user and return if they granted permission or not
    // // Android will just grant without prompting
    // PushNotifications.requestPermissions().then(result => {
    //   console.log('PushNotifications.requestPermissions', result);
    //   if (result.receive === 'granted') {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    //   } else {
    //     // Show some error
    //   }
    // });

    // // Some issue with our setup and push will not work
    // PushNotifications.addListener('registrationError',
    //   (error: any) => {
    //     alert('Error on registration: ' + JSON.stringify(error));
    //   }
    // );

    // // Method called when tapping on a notification
    // PushNotifications.addListener('pushNotificationActionPerformed',
    //   (notification: ActionPerformed) => {
    //     alert('Push action performed: ' + JSON.stringify(notification));
    //   }
    // );

    /* Load notifications on:
     * 1. App resume
     * 2. Push notification input if app is opened
     * 3. Mark notification as read (on notifications effects)
     */

    // 1. App resume
    
    CapacitorApp.addListener('resume', () => {
      this.store.dispatch(NotificationActions.loadNotifications());
    });
    

    // 2. Show us the notification payload if the app is open on our device
    // if (Capacitor.getPlatform() !== 'web') {
    //   // On success, we should be able to receive notifications
    //   PushNotifications.addListener('registration', (token: Token) => {
    //     console.log('ENARA PushNotifications.addListener', token.value);
    //     alert('ENARA Push registration success, token: ' + token.value);
    //   });
    //   PushNotifications.addListener(
    //     'pushNotificationReceived',
    //     (notification: PushNotificationSchema) => {
    //       alert('ENARA:  Push received: ' + JSON.stringify(notification));
    //       this.store.dispatch(NotificationActions.loadNotifications());
    //     }
    //   );
    // }
  }
}
