import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canLoadAuth, canActivateAuth, canLoadNaiha } from '@nai-libs/shared/utility/src';

const routes: Routes = [
  
  {
    path: 'selector-familiar',
    loadChildren: () =>
      import('@nai-libs/user/features/service-receiver-detailed-selector-feature').then(
        (m) => m.ServiceReceiverDetailedSelectorFeatureModule
      ),
    canLoad: [canLoadAuth],
  },
  

  
  {
    path: 'recuperar-contrasena',
    loadChildren: () =>
      import('@nai-libs/user/features/password-recovery-feature').then(
        (m) => m.PasswordRecoveryFeatureModule
      ),
  },  
  {
    path: 'familiar-profile/save-password',
    loadChildren: () =>
    import('@nai-libs/user/features/new-password-feature').then(
      (m) => m.NewPasswordFeatureModule
    ),
  },
  
  {
    path: 'legal',
    loadChildren: () =>
      import('@nai-libs/user/features/legal-feature').then(
        (m) => m.LegalFeatureModule
      ),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('@nai-libs/user/features/profile-feature').then(
        (m) => m.ProfileFeatureModule
      ),
    canLoad: [canLoadAuth],
  },
  
  {
    path: 'members',
    loadChildren: () =>
      import('@nai-libs/members/features/members-feature').then(
        (m) => m.MembersFeatureModule
      ),
    canLoad: [canLoadAuth],
  },
  
  
  {
    path: 'notificaciones',
    loadChildren: () =>
      import('@nai-libs/notification/features/notification-feature').then(
        (m) => m.NotificationFeatureModule
      ),
    canLoad: [canLoadAuth],
  },
  
  
  {
    path: 'estadisticas',
    loadChildren: () =>
      import('@nai-libs/task/features/task-statistics-feature/src').then(
        (m) => m.TaskStatisticsFeatureModule
      ),
    canLoad: [canLoadAuth],
  },
  
  

  
  {
    path: 'login',
    loadChildren: () =>
      import('@nai-libs/user/features/login-feature').then(
        (m) => m.LoginFeatureModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
    import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canLoad: [canLoadAuth],
  },
  

  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
