import { Component, Input } from '@angular/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'nai-button-avatar',
    template: `
    <button
      class="button button--outline button--avatar"
      [class.collapsable]="collapsable"
      [ngClass]="type"
    >
      <nai-avatar [initial]="initial" [color]="color"></nai-avatar>
      <span class="font-subhead-1 text">{{ text }}</span>
    </button>
  `,
    styleUrls: ['./button-avatar.component.scss'],
    standalone: true,
    imports: [NgClass, AvatarComponent],
})
export class ButtonAvatarComponent {
  @Input() color = 'ffb948';
  @Input() initial = '';
  @Input() text = '';
  @Input() collapsable = false;
  @Input() type: 'regular' | 'list-item' = 'regular';
}
