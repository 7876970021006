import { Locale } from './translate.models';

export interface Credentials {
  email: string;
  password: string;
}

export interface PersonReference {
  identifier: Array<string | number>;
  location: Array<string>;
  phone: string;
  email: string;
  role: string;
}

export type UserRoleID = 'relative' | 'carer';

export type UserRole =
  | 'Familiar referente'
  | 'Familiar usuario'
  | 'Cuidadora formal'
  | 'Sin acceso'
  | 'Cercana-user';

export type SelectedUserRole = 'Service Receiver' | UserRole;

export const UserRoleIDMapping = {
  'Sin acceso': 'relative',
  'Familiar referente': 'relative',
  'Familiar usuario': 'relative',
  'Cercana-user': 'relative',
  'Cuidadora formal': 'carer',
};

export interface UserIdentifier {
  name: string;
  'first-surname': string;
  'second-surname': string;
  username: string;
}
export interface LoggedUser {
  language: Locale;
  sex: string;
  'bank-account': string;
  'naiha-fam-access': UserRole;
  'contact-phone': {
    landline: string;
    mobile: string;
  };
  relationship: string;
  'identify-document': {
    type: string;
    number: string;
  };
  town: string;
  'e-mail': string;
  'cp-home': string;
  'terms-and-conditions-of-use': boolean;
  'birth-date': string;
  'post-address': string;
  'home-address': {
    type: string;
    name: string;
    hall: string;
    block: string;
    stair: string;
    flat: string;
    door: string;
  };
  'communication-of': Array<string>;
  'privacy-policy': boolean;
  id: number;
  'contact-type': Array<string>;
  identifier: UserIdentifier;
  province: string;
  avatar: string;
  'naiha-family'?: boolean;
}

export interface ServiceReceiverIntervention {
  'end-date': {
    date: string;
    hour: string;
    instant: string;
  };
  'start-date': {
    date: string;
    hour: string;
    instant: string;
  };
}
export interface ServiceReceiver {
  institution: string | null;
  commercial: PersonReference;
  'empleador?': boolean;
  study: string | null;
  sex: string;
  intervention?: ServiceReceiverIntervention;
  tis: string;
  'dependency-level'?: 'Bajo' | 'Medio' | 'Alto';
  'bank-account': string;
  'contact-phone': {
    landline: string;
    mobile: string;
  };
  'direct-debit': string | null;
  'user-location': string;
  'identify-document': {
    type: string;
    number: string;
  };
  town: string;
  'e-mail': string;
  'birth-date': string;
  'post-address': string;
  'home-address': {
    type: string;
    name: string;
    hall: string;
    block: string;
    stair: string;
    flat: string;
    door: string;
  };
  'selected-ts': PersonReference;
  language: string;
  carer?: {
    'activity-label': string;
    schedule: {
      data: string[][][];
      description?: string;
    };
  };
  id: number;
  identifier: UserIdentifier;
  'client-phone': string;
  'empleador-extra-data': {
    'afiliation-num': string;
    'contribution-account-num': string;
    'mum-name': string;
    'dad-name': string;
    birthplace: {
      town: string;
      province: string;
      country: string;
    };
  };
  'some-dgi-done?': boolean;
  province: string;
  'reference-nurse': PersonReference;
  avatar: string;
}

export interface User {
  'valid-token?': boolean;
  'logged-user': LoggedUser;
  'service-receiver': ServiceReceiver | ServiceReceiver[];
  token?: UserToken;
}

export interface SelectedUser {
  'user-id': string;
  avatar: string;
  'user-role': SelectedUserRole;
  identifier: UserIdentifier;
}

export interface UserEditableData {
  username: string;
  email: string;
  phone: string;
  relation?: string;
}

export interface NewUser {
  username: string;
  name: string;
  firstSurname: string;
  secondSurname: string | null;
  DNI: string;
  birthDate: string;
  ZIPCode: string;
  email: string;
  phone: string;
  commercial: boolean;
  terms?: boolean;
  privacy?: boolean;
  password?: string;
  clientPhone?: string;
}

export interface SignUpResponse {
  'success?': boolean;
  'user-id': string;
}

export type ServiceReceiverRelation =
  | 'yo'
  | 'conyuge'
  | 'hijo'
  | 'cuidador'
  | 'padre/madre'
  | 'hermano/a'
  | 'primo/a'
  | 'nieto/a'
  | 'sobrino/a'
  | 'yerno/nuera'
  | 'amigo/a'
  | 'vecino/a';
export interface NewServiceReceiver {
  relation: ServiceReceiverRelation;
  name: string;
  firstSurname: string;
  secondSurname: string | null;
  DNI: string;
  birthDate: string;
  ZIPCode: string;
}

export interface ValidateEmailResponse {
  'success?': boolean;
  token: UserToken;
}

export interface UserToken {
  value: string;
  domain: string;
  'max-age': number;
}
export interface TermsAndPolicyResponse {
  'terms-html': string,
  'privacy-policy-html': string
}
