// export type FormQuestionType =
//   | 'select'
//   | 'select-button'
//   | 'select-radio'
//   | 'multiselect-checkbox'
//   | 'multiselect-button'
//   | 'weekly-hour-frequency'
//   | 'weekly-frequency'
//   | 'text-area';

// export type FormQuestionType =
//   | 'select' -> 'select-one'
//   | 'select-button' -> 'table-select-one'
//   | 'select-radio'
//   | 'multiselect-checkbox'
//   | 'multiselect-button' -> 'table-select-many'
//   | 'weekly-hour-frequency' (se puede cambiar a 'composite' pero pierde la lógica de bloquear días si ya están seleccionados)
//   | 'weekly-frequency'
//   | 'text-area' -> 'long-text2'

// new -> 'select-many' (modal con opciones) OK
// new -> 'text' (texto corto en una línea) OK
// new -> 'number' OK
// new -> 'date'
// new -> 'composite' OK

export type FormQuestionType =
  | 'select-one'
  | 'table-select-one'
  | 'select-radio'
  | 'multiselect-checkbox'
  | 'table-select-many'
  | 'weekly-hour-frequency'
  | 'weekly-frequency'
  | 'long-text2'
  | 'select-many'
  | 'text'
  | 'number'
  | 'date'
  | 'composite';

export interface WeeklyHourFrequency {
  hour: string;
  weekdays: number[];
}
export interface FormCompositeAnswer {
  [i: number]: { [j: number]: FormSubmissionAnswer };
}
export interface FormCompositeError {
  [id: string]: string[];
}

export type FormAnswer =
  | string
  | string[]
  | WeeklyHourFrequency[]
  | WeeklyHourFrequency
  | number[]
  | boolean
  | FormCompositeAnswer;

export interface FormQuestionOption {
  id: string;
  text: string;
}

export type FormOperator = '>=' | '>' | '<=' | '<' | '~=' | '=';

export interface ActivationRule {
  'master-question-id': string;
  operator: FormOperator;
  answer: FormAnswer; // Respuesta que tiene que tener la pregunta padre para que la pregunta hija se muestre
}

export interface FormQuestion {
  id: string; // Id único que identifica a la pregunta
  label?: string;
  type: FormQuestionType;
  required: boolean;

  options?: FormQuestionOption[];
  placeholder?: string;
  'activation-rule'?: ActivationRule; // Pregunta padre
  subquestions?: FormQuestion[];
  'composite-questions'?: FormQuestion[];
  'radio-group-id'?: string; // Cuando hay varias preguntas de tipo de radio button, se necesita un id para identificar que pertenecen al mismo grupo.
  answer?: FormAnswer; // Dependerá del tipo de pregunta
}

export interface FormTip {
  title: string;
  desc: string; // La descripción en HTML (no wrappear en <section> ni <p>, solo añadir <b> o <i> desde back si se quiere)
}

export interface FormStep {
  id: string;
  icon: string;
  title: string;
  questions: FormQuestion[];
  tip?: FormTip;
}

export interface FormSection {
  id: string;
  image: string;
  title: string;
  desc: string;
  steps: FormStep[];
}

export interface Form {
  id: string; // Debe identificar la versión del form de manera única
  timestamp?: string; // El momento en el que se han registrado las respuestas
  'is-open': boolean;
  'duration-in-minutes': number;
  image: string;
  'background-color': string; //HEX
  title: string;
  desc: string;
  sections: FormSection[];
}

export interface FormSubmissionAnswer {
  id: string; // Id único de la pregunta
  'radio-group-id'?: string;
  'master-question-id'?: string;
  answer: FormAnswer;
}


export interface FormSubmissionError {
  id: string;
  errors: string[];
}

export interface FormSubmissionErrorComposite {
  id: string;
  type: 'composite';
  errors: {[index: string]: FormCompositeError }
}


export interface FormSubmission {
  id: string; // Id único del form al que se contesta
  answers: FormSubmissionAnswer[];
  errors: Array<FormSubmissionError| FormSubmissionErrorComposite>;
}

export interface WeeklyHourFrequencyError {
  hour?: string;
  weekdays?: string;
}

export interface FormSummary {
  id: string;
  title: string;
  sections: FormSummarySection[];
}

export interface FormSummarySection {
  id: string;
  title: string;
  image: string;
  progress: number;
  content: string; // HTML
}

export interface FormStatus {
  id: string;
  progress: number;
  'evaluation-date': string;
  'reevaluation-date': string;
}

export interface FormSummary2Area {
  area: string;
  problem: string | string[];
  'background-color'?: string;
  'sub-caps'?: FormSummary2Area[];
}
export interface FormSummary2Section {
  title: string;
  slug: string;
  icon: string;
  areas: FormSummary2Area[];
}

// DGI style
export interface FormSummary2 {
  id: string;
  results: FormSummary2Section[];
  'editable?': boolean;
}
