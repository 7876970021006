import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Form,
  FormStatus,
  FormSubmissionAnswer,
  FormSummary,
  FormSummary2,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private env: AppConfig
  ) {}

  fetchFormNewComplete(
    user: User,
    id: string,
    serviceReceiver?: ServiceReceiver
  ): Observable<Form> {
    let params = new HttpParams();

    params = params.append('id', id);
    params = params.append('familiar-email', user['logged-user']['e-mail']);
    params = params.append('version', 'new-complete');

    if (serviceReceiver)
      params = params.append('client-phone', serviceReceiver['client-phone']);

    return this.http.get<Form>(
      `${this.env.apiUrl}health/fetch-survey-model?${params}`
    );

    // return of(TASK_FORM_NEW);
  }

  saveForm(
    user: User,
    serviceReceiver: ServiceReceiver,
    formAnswers: FormSubmissionAnswer[],
    id: string
  ): Observable<string> {
    const currentDate = new Date(); //TODO/NOTA: esto no tendría la zona horaria correcta
    const timestamp = currentDate.getTime();
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      id,
      timestamp,
      answers: formAnswers,
    };
    return this.http.post(
      `${this.env.apiUrl}health/save-survey-answers`,
      paramsObj,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      }
    );
  }

  fetchFormSummary(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<FormSummary | FormSummary2> {
    const paramsObj = {
      id,
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      selectedUser: selectedUser['user-id'],
    };

    const params = new URLSearchParams(paramsObj).toString();

    return this.http
      .get<FormSummary | FormSummary2>(
        `${this.env.apiUrl}health/fetch-survey-results?${params}`
      ).pipe(map(response => ({...response, id})));
  }

  fetchFormStatus(
    id: string,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser?: SelectedUser
  ): Observable<FormStatus> {
    let params = new HttpParams();

    params = params.append('id', id);
    params = params.append('client-phone', serviceReceiver['client-phone']);
    params = params.append('familiar-email', user['logged-user']['e-mail']);

    if (selectedUser)
      params = params.append('selectedUser', selectedUser['user-id']);

    return this.http.get<FormStatus>(
      `${this.env.apiUrl}health/fetch-survey-status?${params}`
    );
  }
}
