import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  LoggedUser,
  SelectedUser,
  SelectedUserRole,
  ServiceReceiver,
} from '@nai-libs/data-access';
import { UserActions, UserSelectors } from '@nai-libs/user/data-access';
import { Store } from '@ngrx/store';
import { NotificationSelectors } from 'libs/notification/data-access/src';
import { map } from 'rxjs';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { DynamicIconDirective } from '@nai-libs/directives/dynicon';
import { EdercareService } from '@nai-libs/edercare/data-access';
import { AvatarComponent } from '@nai-libs/standalone/avatar';
import { ButtonAvatarComponent } from '@nai-libs/standalone/button-avatar';
import { MenuListComponent } from '@nai-libs/standalone/menu-list';
import { NotificationDotComponent } from '@nai-libs/standalone/notification-dot';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'nai-menu',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    AvatarComponent,
    RouterModule,
    DynamicIconDirective,
    MenuListComponent,
    TranslateModule,
    ButtonAvatarComponent,
    NotificationDotComponent,
  ],
  template: `
    <ion-menu
      menuId="main-menu"
      contentId="main-content"
      class="menu"
      side="end"
      swipeGesture="false"
      *ngIf="{
        serviceReceiver: serviceReceiver$ | async,
        user: user$ | async,
        selectedUser: selectedUser$ | async
      } as vm"
    >
      <header>
        <ng-container *ngIf="env.features.edercare; else defaultAvatar">
          <figure>
            <img [src]="'app-assets/images/edercare-logo.svg'" />
          </figure>
        </ng-container>
        <ng-template #defaultAvatar>
          <nai-avatar
            *ngIf="!env.features.edercare"
            [initial]="vm.selectedUser?.identifier?.username?.charAt(0)"
            [color]="vm.selectedUser?.avatar"
            size="xxl"
          ></nai-avatar>
        </ng-template>
        <p class="font-headline-5">
          {{ vm.selectedUser?.identifier?.username }}
        </p>
      </header>
      <ion-content>
        <div class="content">
          <!-- Administrar perfil -->
          <section class="menu-buttons menu-container">
            <div>
              <ion-menu-toggle id="main-menu">
                <a
                  class="menu-button"
                  [routerLink]="
                    env.features.edercare ? ['/edercare/profile'] : ['/perfil']
                  "
                >
                  <span
                    class="edit-icon"
                    naiDynIcon
                    [path]="'assets/icons/person-outline.svg'"
                  ></span>

                  <span class="font-subhead-1 menu-button-label">
                    {{ 'USER.PROFILE.MENU.SEE_PROFILE' | translate }}
                  </span>
                </a>
              </ion-menu-toggle>

              <ng-container
                *ngIf="!env.features.edercare || (canAccessNaiha$ | async)"
              >
                <!-- Notificaciones -->
                <ion-menu-toggle
                  *ngIf="env.features.notifications"
                  id="main-menu"
                >
                  <a class="menu-button" [routerLink]="['/notificaciones']">
                    <div class="notifications-icon-wrapper">
                      <span
                        class="edit-icon"
                        naiDynIcon
                        [path]="'assets/icons/bell.svg'"
                      ></span>

                      <nai-notification-dot
                        *ngIf="notifications$ | async as notifications"
                        [number]="notifications"
                      ></nai-notification-dot>
                    </div>

                    <span class="font-subhead-1 menu-button-label">
                      {{ 'USER.PROFILE.MENU.NOTIFICATIONS' | translate }}
                    </span>
                  </a>
                </ion-menu-toggle>

                <!-- Estadísticas -->
                <ion-menu-toggle *ngIf="env.features.statistics" id="main-menu">
                  <a class="menu-button" [routerLink]="['/estadisticas']">
                    <span
                      class="edit-icon"
                      naiDynIcon
                      [path]="'assets/icons/stats.svg'"
                    ></span>

                    <span class="font-subhead-1 menu-button-label">
                      {{ 'USER.PROFILE.MENU.STATISTICS' | translate }}
                    </span>
                  </a>
                </ion-menu-toggle>
                <ion-menu-toggle
                  *ngIf="
                    env.features.members &&
                    (userRole$ | async) === 'Familiar referente'
                  "
                  id="main-menu"
                >
                  <a class="menu-button" [routerLink]="['/', 'members']">
                    <span
                      class="edit-icon"
                      naiDynIcon
                      [path]="'assets/icons/face.svg'"
                    ></span>

                    <span class="font-subhead-1 menu-button-label">
                      {{ 'USER.PROFILE.MENU.MEMBERS' | translate }}
                    </span>
                  </a>
                </ion-menu-toggle>
              </ng-container>

              <a
                *ngIf="env.features.edercare"
                class="menu-button"
                [routerLink]="edercareService.getRoute('service/history')"
                [routerDirection]="'forward'"
                (click)="closeMenu()"
              >
                <span naiDynIcon [path]="'assets/icons/care.svg'"></span>
                <span class="font-subhead-1 menu-button-label"
                  >Mis servicios</span
                >
              </a>
            </div>
          </section>

          <div *ngIf="env.features.edercare" class="menu__bottom">
            <button
              class="button button--edercare button--link"
              (click)="logout()"
            >
              <span>Cerrar sesión</span>
            </button>
          </div>

          <!-- Cambiar de perfil -->
          <section *ngIf="env.features.switchUser" class="profile-change">
            <h1 class="font-overline">
              {{ 'USER.PROFILE.MENU.CHANGE_PROFILE' | translate }}
            </h1>

            <!--TODO:check if we can have multiple service receivers-->
            <ion-menu-toggle id="main-menu">
              <nai-button-avatar
                *ngIf="
                vm.serviceReceiver &&
                vm.selectedUser?.['user-id'] !== vm.serviceReceiver?.id
              "
                [color]="vm.serviceReceiver.avatar || '#ffb948'"
                [text]="vm.serviceReceiver.identifier.name || ''"
                [initial]="vm.serviceReceiver.identifier.name.charAt(0) || ''"
                (click)="changeSelectedUser(vm.serviceReceiver)"
                [type]="'list-item'"
                size="large"
              >
              </nai-button-avatar>
            </ion-menu-toggle>

            <ion-menu-toggle id="main-menu">
              <nai-button-avatar
                *ngIf="vm.user?.['logged-user'] && (vm.selectedUser?.['user-id'] !== vm.user?.['logged-user']?.id)"
                [color]="vm.user?.['logged-user']?.avatar || '#ffb948'"
                [text]="vm.user?.['logged-user']?.identifier?.name || ''"
                [initial]="vm.user?.['logged-user']?.identifier?.name?.charAt(0) || ''"
                (click)="changeSelectedUser(vm.user?.['logged-user'])"
                [type]="'list-item'"
                size="large"
              >
              </nai-button-avatar>
            </ion-menu-toggle>

            <!-- <ion-menu-toggle class="button-close-menu">
              <button class="button button--clear">Cerrar menú</button>
            </ion-menu-toggle> -->

            <!-- {{ vm.selectedUser | json }}
          {{ vm.selectedUser?.['user-id'] | json }} -->
          </section>
        </div>
      </ion-content>
    </ion-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  user$ = this.store.select(UserSelectors.selectUser);
  serviceReceiver$ = this.store.select(UserSelectors.selectServiceReceiver);
  selectedUser$ = this.store.select(UserSelectors.selectSelectedUser);
  userRole$ = this.store.select(UserSelectors.selectLoggedUserRole);
  canAccessNaiha$ = this.store.select(UserSelectors.canAccessNaiha);
  notifications$ = this.store
    .select(NotificationSelectors.selectNotifications)
    .pipe(map((notifications) => notifications?.notifications ?? 0));
  constructor(
    private store: Store,
    @Inject(APP_CONFIG) public env: AppConfig,
    public edercareService: EdercareService,
    private menuCtrl: MenuController
  ) {}

  changeSelectedUser(user: LoggedUser | ServiceReceiver | undefined) {
    if (user) {
      let userId: string;
      let selectedUserRole: SelectedUserRole;

      if ('client-phone' in user) {
        userId = user['client-phone'];
      } else if ('id' in user) {
        userId = user['e-mail'];
      } else {
        return;
      }
      if ('naiha-fam-access' in user) {
        selectedUserRole = user['naiha-fam-access'];
      } else {
        selectedUserRole = 'Service Receiver';
      }
      const selectedUser: SelectedUser = {
        'user-id': userId,
        avatar: user.avatar,
        'user-role': selectedUserRole,
        identifier: user.identifier,
      };
      this.store.dispatch(
        UserActions.changeSelectedUser({
          selectedUser,
        })
      );
    }
  }

  closeMenu() {
    this.menuCtrl.close('main-menu');
  }
  logout() {
    this.closeMenu();
    this.store.dispatch(UserActions.logout());
  }
}
