import { createReducer, on } from '@ngrx/store';
import {
  ArticleResource,
  FormativeAreaInfo,
  GlossaryWord,
  RequestStatus,
  ArticleResponse,
  Article,
  FormativeAreaResponse,
} from '@nai-libs/data-access';
import * as ArticleActions from './article.actions';
import { addOrUpdateArray } from '@nai-libs/shared/utility/src';

export const ARTICLE_REDUCER_KEY = 'article';

export interface ArticleState {
  // All articles
  articles: Article[];

  suggestedArticles: string[];
  suggestedArticlesStatus: RequestStatus;

  areaInfoList: FormativeAreaInfo[];
  areaInfoListStatus: RequestStatus;

  glossaryWords: GlossaryWord[];
  glossaryWordStatus: RequestStatus;

  articleStatus: RequestStatus;
  resources: ArticleResource[];
  resourcesStatus: RequestStatus;

  markArticleAsReadStatus: RequestStatus;
}

const initialState: ArticleState = {
  articles: [],
  areaInfoList: [],
  suggestedArticles: [],
  resources: [],
  suggestedArticlesStatus: { pending: false },
  areaInfoListStatus: { pending: false },
  articleStatus: { pending: false },
  resourcesStatus: { pending: false },
  glossaryWords: [],
  glossaryWordStatus: { pending: false },
  markArticleAsReadStatus: { pending: false },
};

export const articleReducer = createReducer(
  initialState,
  on(ArticleActions.loadSuggestedArticles, (state) => ({
    ...state,
    suggestedArticlesStatus: { pending: true },
  })),
  on(ArticleActions.loadSuggestedArticlesSuccess, (state, { articles }) => ({
    ...state,
    suggestedArticles: articles.map((article) => article.codigo),
    articles: addOrUpdateArray(state.articles, articles, 'codigo'),
    suggestedArticlesStatus: { pending: false },
  })),
  on(ArticleActions.loadSuggestedArticlesFailure, (state) => ({
    ...state,
    suggestedArticlesStatus: { pending: false },
  })),
  on(ArticleActions.loadAreaInfoList, (state) => ({
    ...state,
    areaInfoListStatus: { pending: true },
  })),
  on(ArticleActions.loadAreaInfoListSuccess, (state, { areaInfoList }) => ({
    ...state,
    areaInfoList,
    areaInfoListStatus: { pending: false },
  })),
  on(ArticleActions.loadAreaInfoListFailure, (state) => ({
    ...state,
    areaInfoListStatus: { pending: false },
  })),
  on(ArticleActions.loadArea, (state) => ({
    ...state,
    areaStatus: { pending: true },
  })),
  on(ArticleActions.loadAreaSuccess, (state, { area }) => {
    return {
      ...state,
      articles: addOrUpdateArray(
        state.articles,
        area['formative-area-articles'],
        'codigo'
      ),
      areaInfoList: addOrUpdateArray(
        state.areaInfoList,
        [area['formative-area']],
        'NANDA-code'
      ),
      areaStatus: { pending: false },
    };
  }),
  on(ArticleActions.loadAreaFailure, (state) => ({
    ...state,
    areaStatus: { pending: false },
  })),

  on(ArticleActions.loadArticle, (state) => ({
    ...state,
    articleStatus: { pending: true },
  })),
  on(ArticleActions.loadArticleSuccess, (state, { articles }) => ({
    ...state,
    articles: addOrUpdateArray(state.articles, articles, 'codigo'),
    articleStatus: { pending: false },
  })),
  on(ArticleActions.loadArticleFailure, (state) => ({
    ...state,
    articleStatus: { pending: false },
  })),

  on(ArticleActions.loadArticleResources, (state) => ({
    ...state,
    resourcesStatus: { pending: true },
  })),
  on(ArticleActions.loadArticleResourcesSuccess, (state, { resources }) => ({
    ...state,
    resources: resources,
    resourcesStatus: { pending: false },
  })),
  on(ArticleActions.loadArticleResourcesFailure, (state) => ({
    ...state,
    resourcesStatus: { pending: false },
  })),

  on(ArticleActions.loadGlosaryWord, (state) => ({
    ...state,
    glossaryWordStatus: { pending: true },
  })),
  on(ArticleActions.loadGlosaryWordSuccess, (state, { glossaryWord }) => ({
    ...state,
    glossaryWords: addOrUpdateArray(
      state.glossaryWords,
      [glossaryWord],
      'word'
    ),
    glossaryWordStatus: { pending: false },
  })),
  on(ArticleActions.loadGlosaryWordFailure, (state) => ({
    ...state,
    glossaryWordStatus: { pending: false },
  })),

  on(ArticleActions.markArticleAsRead, (state) => ({
    ...state,
    markArticleAsReadStatus: { pending: true },
  })),
  on(ArticleActions.markArticleAsReadSuccess, (state) => ({
    ...state,
    markArticleAsReadStatus: { pending: false },
  })),
  on(ArticleActions.markArticleAsReadFailure, (state, error) => ({
    ...state,
    markArticleAsReadStatus: { pending: false, error },
  }))
);
