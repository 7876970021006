import { AppConfig } from '@nai-libs/shared/data-access/src';

export const environment: AppConfig = {
  production: false,
  appName: 'default',
  domain: 'ubikare.io',
  baseUrl: 'https://naiha.configurable.ubikare.io',
  apiUrl: 'https://naiha.configurable.ubikare.io/api-nf/',
  
  availableLanguages: ['es',],
  defaultLanguage: 'es',
  features: {
    edercare: false,

    wall: true,
    activities: true,
    articles: true,
    clockIn: false,
    taskList: false,
    shortcuts: undefined,
    goals: undefined,

    taskForm: true,
    symptoms: undefined,
    calendar: true,
    CMDForm: true,
    DGI: true,
    healthRecord: undefined,
    medication: undefined,
    PAECH: false,

    healthMonitor: true,
    physicalEducation: true,
    danceTherapy: false,
    neuro: true,
    homeAccessibility: true,
    educationalProgram: true,
    nutrition: true,
    zaintzaQuiz: false,
    personalProgress: true,
    editPlan: false,

    videoconsultations: true,
    respiro: false,
    documents: true,
    shop: true,
    buyAndSell: true,
    professionals: true,

    faq: true,
    chats: true,
    contact: true,
    community: true,
    satisfactionSurvey: true,

    members: true,
    statistics: true,
    notifications: true,
    switchUser: true,

    editAvatar: true,
    editProfile: true,
    changePassword: true,
    changeLanguage: true,
    passwordRecovery: true,

    switchServiceReceiver: true,
  },
};

