import { Type } from '@angular/core';
import { Appointment } from './appointment.models';
import { Task } from './task.models';

export interface Timeslot {
  start: string;
  end: string;
  text: string;
  shortText: string;
  events: Event[];
}


/**
 * 'Mañana' -> Por la mañana De 07:00 a 11:00 -> p1 (goals trophy)
 * 'Media Mañana' -> A media mañana De 11:00 a 13:00 --> p2 (goals trophy)
 * 'Mediodía' -> Al mediodía De 13:00 a 16:00 --> p3 (goals trophy)
 * 'Tarde' -> Por la tarde De 16:00 a 20:00 --> p4 (goals trophy)
 * 'Noche' -> Por la noche De 20:00 a 07:00 --> p5 (goals trophy)
 * MACROSAD:
 * 'Noche' -> Por la noche De 20:00 a 23:00 --> p5 (goals trophy)
 * 'Cuidados nocturnos' -> Cuidados nocturnos De 23:00 a 07:00 --> p6 (goals trophy)
 */

export const ALL_TIMESLOTS: Timeslot[] = [
  {
    start: '0',
    end: '0',
    text: 'SCHEDULE.MODELS.TIMESLOT.THROUGHOUT_THE_DAY.FULL',
    shortText: 'SCHEDULE.MODELS.TIMESLOT.THROUGHOUT_THE_DAY.SHORT',
    events: [],
  },
  {
    start: '7',
    end: '11',
    text: 'SCHEDULE.MODELS.TIMESLOT.MORNING.FULL',
    shortText: 'p1',
    events: [],
  },
  {
    start: '11',
    end: '13',
    text: 'SCHEDULE.MODELS.TIMESLOT.MID-MORNING.FULL',
    shortText: 'p2',
    events: [],
  },
  {
    start: '13',
    end: '16',
    text: 'SCHEDULE.MODELS.TIMESLOT.NOON.FULL',
    shortText: 'p3',
    events: [],
  },
  {
    start: '16',
    end: '20',
    text: 'SCHEDULE.MODELS.TIMESLOT.AFTERNOON.FULL',
    shortText: 'p4',
    events: [],
  },
  {
    start: '20',
    end: '23',
    text: 'SCHEDULE.MODELS.TIMESLOT.NIGHT.FULL',
    shortText:'p5',
    events: [],
  },
  {
    start: '23',
    end: '7',
    text: 'SCHEDULE.MODELS.TIMESLOT.NIGHT_CARES.FULL',
    shortText: 'p6',
    events: [],
  },
];

export type timeslotText =
  | 'TAREAS ASOCIADAS a la jornada 1 VEZ/dia'
  | 'Mañana'
  | 'Media Mañana'
  | 'Mediodía'
  | 'Tarde'
  | 'Noche'
  | 'Cuidados nocturnos';

export const timeslotRelation = {
  'TAREAS ASOCIADAS a la jornada 1 VEZ/dia': ALL_TIMESLOTS[0],
  Mañana: ALL_TIMESLOTS[1],
  'Media Mañana': ALL_TIMESLOTS[2],
  Mediodía: ALL_TIMESLOTS[3],
  Tarde: ALL_TIMESLOTS[4],
  Noche: ALL_TIMESLOTS[5],
  'Cuidados Nocturnos': ALL_TIMESLOTS[6],
};

export type Event = Appointment | Task;

export type EventFilter = 'all' | 'appointments' | 'tasks';

export interface MarkArticleResponse {
  'success?': boolean;
  message: string;
}
export interface TimeOrderedComponent {
  orderBy: { datetime: Date; order: number };
  data: any;
  component: Type<any>;
}
