import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { DynamicIconDirective } from '../../../../../utility/src/lib/directives/dynicon/dynicon.directive';

@Component({
    selector: 'nai-avatar',
    template: `
    <div class="avatar-content">
      <span class="font-size-bigger">{{ initial }}</span>
      <div class="edit-icon">
        <span naiDynIcon [path]="'assets/icons/edit.svg'"></span>
      </div>
    </div>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./avatar.component.scss'],
    standalone: true,
    imports: [DynamicIconDirective],
})
export class AvatarComponent {
  @Input() initial? = '';
  @Input() color? = '#ffb948';
  @Input() size: 'small' | 'medium' | 'large' | 'xl' | 'xxl' = 'medium';

  @Input() editable = false;

  @HostBinding('class') get class() {
    return `avatar avatar--${this.size} ${
      this.editable ? 'avatar--editable' : ''
    }`;
  }

  @HostBinding('style') get style() {
    const color = this.color ?? '#ffb948';
    return `background-color: ${color}`;
  }
}
